<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<QuickfoldersList />
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

import CompaniesList from '@/components/Eurex/Quickfolders/CompaniesList'
import Vue from 'vue'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";

export default {
	name: 'QuickfoldersContainer',
	components: {
		QuickfoldersList: () => ({
			component: import('@/components/Eurex/Quickfolders/QuickfoldersList')
		})
	},
	mixins: [
		QuickFoldersModuleGuard,
		HandleSideBarMixin,
	],
	computed: {
		...mapState({
			companies: state => state.company.list,
			isAccountant: state => state.auth.isAccountant
		})
	},
	watch: {
		companies: {
			handler: 'init',
			immediate: true
		}
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this);
		this.setHasSidebar(false);
		this.setShowSidebar(false);
	},
	methods: {
		init: function () {
			if (this.isAccountant && this.companies && this.companies.length > 1) {
				const CompaniesListClass = Vue.extend(CompaniesList)
				const companiesListComponent = new CompaniesListClass({
					parent: this
				})
				this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
				this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
					canMinimize: this.$vuetify.breakpoint.mdAndUp,
					startHidden: this.$vuetify.breakpoint.smAndDown,
					canClose: this.$vuetify.breakpoint.smAndDown,
					title: this.$t('client_workspace.tasks.filters.companies'),
					moduleInstance: companiesListComponent,
					emitter: this
				});
				this.setHasSidebar(true);
				this.setShowSidebar(this.$vuetify.breakpoint.mdAndUp);
			} else {
				this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
			}
		}
	}
}
</script>
