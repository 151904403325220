<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout v-if="companies && selectedCompany" fill-height column>
		<v-flex v-show="!mini" shrink px-3>
			<v-text-field v-model="companiesSearch" hide-details single-line clearable :label="$t('actions.search')" prepend-inner-icon="search"></v-text-field>
		</v-flex>
		<v-flex v-show="!mini" shrink>
			<v-layout row justify-space-around>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="doSortByName()">
								<v-badge color overlap :top="sortByName === true" :bottom="sortByName === false">
									<template v-slot:badge>
										<v-icon color="secondary">{{ sortByName === null ? '' : sortByName ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
									</template>
									<v-icon color="primary">sort_by_alpha</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>
							{{
								sortByName === null ? $t('quickfolders.sort_by_name') : sortByName ? $t('quickfolders.sort_by_name_asc') : $t('quickfolders.sort_by_name_desc')
							}}
						</span>
					</v-tooltip>
				</v-flex>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="doSortByDate()">
								<v-badge color overlap :top="sortByDate === true" :bottom="sortByDate === false">
									<template v-slot:badge>
										<v-icon color="secondary">{{ sortByDate === null ? '' : sortByDate ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
									</template>
									<v-icon color="primary">update</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>
							{{
								sortByDate === null ? $t('quickfolders.sort_by_date') : sortByDate ? $t('quickfolders.sort_by_date_asc') : $t('quickfolders.sort_by_date_desc')
							}}
						</span>
					</v-tooltip>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-divider v-if="!mini" />
		<v-flex scroll-y grow>
			<w-virtual-list :items="filteredCompanies">
				<template v-slot:default="{ item: company }">
					<v-hover>
						<template v-slot:default="{ hover }">
							<v-list-tile avatar :value="company.id === selectedCompany.id" @click="selectCompany(company)">
								<v-list-tile-avatar v-if="mini">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-avatar size="2.5em" class="pointer" color="primary" v-on="on">
												<v-flex white--text>{{ company.abbreviation }}</v-flex>
											</v-avatar>
										</template>
										<span v-text="company.client_code ? `${company.company} (${company.client_code})` : company.company" />
									</v-tooltip>
								</v-list-tile-avatar>
								<v-list-tile-avatar v-else>
									<v-avatar size="2.5em" class="pointer" color="primary">
										<v-flex white--text>{{ company.abbreviation }}</v-flex>
									</v-avatar>
								</v-list-tile-avatar>
								<v-list-tile-content v-show="!mini">
									<v-list-tile-title>
										<v-flex v-if="hover && company.nameHtml" text-truncate v-html="company.nameHtml" />
										<v-flex v-else-if="hover" text-truncate v-text="company.fullname" />
										<v-tooltip v-else bottom>
											<template v-slot:activator="{ on }">
												<v-flex v-if="company.nameHtml" v-on="on" v-html="company.nameHtml" />
												<v-flex v-else v-on="on" v-html="company.fullname" />
											</template>
											<v-flex text-truncate v-text="company.client_code ? `${company.company} (${company.client_code})` : company.company" />
										</v-tooltip>
									</v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
						</template>
					</v-hover>
				</template>
			</w-virtual-list>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'CompaniesList',
	mixins: [QuickFoldersModuleGuard],
	data: function () {
		return {
			companiesSearch: null,
			counters: [],
			filteredCompanies: [],
			loading: true,
			mini: false,
			orderedAlphaAsc: null,
			showFilters: false,
			sortByName: localStorage.getItem('ecmclsortbyname'),
			sortByDate: localStorage.getItem('ecmclsortbydate')
		}
	},
	computed: {
		...mapState({
			companies: state => state.company.list,
			selectedCompany: state => state.company.selected
		}),
		sortedCompanies: function () {
			return this.doSort(this.companies)
		}
	},
	watch: {
		mini: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		loading: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		sortedCompanies: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		counters: {
			deep: true,
			handler: function () {
				this.updateFilteredCompanies()
			},
			immediate: true
		},
		companiesSearch: {
			handler: function () {
				this.updateFilteredCompanies()
			}
		},
		orderedAlphaAsc: {
			handler: function () {
				this.updateFilteredCompanies()
			}
		},
		sortByName: {
			handler: function () {
				this.updateFilteredCompanies()
			}
		},
		sortByDate: {
			handler: function () {
				this.updateFilteredCompanies()
			}
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized }
			]
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		selectCompany: function (company) {
			this.appService.goTo({
				params: {
					vendor_id: company.id
				}
			})
		},
		doSortByName: function () {
			if (this.sortByName === null) {
				localStorage.setItem('ecmclsortbyname', true)
				this.sortByName = true
			} else {
				if (this.sortByName === true) {
					localStorage.setItem('ecmclsortbyname', false)
					this.sortByName = false
				} else if (this.sortByName === false) {
					localStorage.removeItem('ecmclsortbyname')
					this.sortByName = null
				}
			}
			localStorage.removeItem('ecmclsortbydate')
			this.sortByDate = null
		},
		doSortByDate: function () {
			if (this.sortByDate === null) {
				localStorage.setItem('ecmclsortbydate', true)
				this.sortByDate = true
			} else {
				if (this.sortByDate === true) {
					localStorage.setItem('ecmclsortbydate', false)
					this.sortByDate = false
				} else if (this.sortByDate === false) {
					localStorage.removeItem('ecmclsortbydate')
					this.sortByDate = null
				}
			}
			localStorage.removeItem('ecmclsortbyname')
			this.sortByName = null
		},
		doSort: function (values) {
			let result = null
			if (values) {
				result = [...values]
				if (this.sortByName !== null) {
					if (this.sortByName) {
						result.sort(this.appService.compareValues('company', 'asc'))
					} else {
						result.sort(this.appService.compareValues('company', 'desc'))
					}
				} else if (this.sortByDate !== null) {
					if (this.sortByDate) {
						result.sort(this.appService.compareValues('created_at', 'asc'))
					} else {
						result.sort(this.appService.compareValues('created_at', 'desc'))
					}
				}
			}
			return result
		},
		updateFilteredCompanies: function () {
			let result = this.sortedCompanies
			result.forEach(company => (company.fullname = company.client_code ? company.company + ' (' + company.client_code + ')' : company.company))
			result = [
				...result
					.filter(
						company =>
							!this.companiesSearch ||
							!this.companiesSearch.trim().length > 0 ||
							company.fullname.trim().toLowerCase().indexOf(this.companiesSearch.trim().toLowerCase()) !== -1
					)
					.map(company => {
						company.nameHtml = this.$highlightMatching(company.fullname, this.companiesSearch)
						return company
					})
			]
			this.filteredCompanies.clear()
			this.filteredCompanies = [...result]
		}
	}
}
</script>
